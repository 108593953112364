import React, { useContext, useState } from 'react'
import axios from 'shared/http'
import verifyIsLoginActive from 'util/verifyIsLoginActive'
import { bindActionCreators } from 'redux'
import { setModalShowRedux } from 'store/modal/actions'
import { connect } from 'react-redux'
import LoginBox from 'containers/LoginBox/LoginBox'

import { Styled } from './Navbar.styled'
import { UI } from 'components/UI/UI.styled'

import MenuMobile from 'components/MenuMobile/MenuMobile'
import NavItems from 'components/NavItems/NavItems'
import NavItemsPrivate from 'components/NavItems/NavItemsPrivate'
import NavbarProfile from 'components/NavbarProfile/NavbarProfile'
import NavbarProfilePrivate from 'components/NavbarProfile/NavbarProfilePrivate'
//import NavbarSearch from 'components/NavbarSearch/NavbarSearch'
//import NavbarSearchMobile from 'components/NavbarSearchMobile/NavbarSearchMobile'
import { MenuIcon } from 'components/Icons/Icons'
import Logo from 'components/Logo/Logo'

import { history } from 'store'
import { TabContext } from 'components/Tab/Tab'
import { useCount } from 'util/context/Count'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Navbar = ({ showModal, props }) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState({})
  const [showLegal, setShowLegal] = React.useState(false)
  const { count, setCount, tabList, setTabList } = useCount()
  const history = useHistory()
  const { handleChangeTab } = useContext(TabContext)
  const [state, setState] = React.useState({
    infoUsuario: [],
    isPerson:
      localStorage.getItem('auth-token') === null
        ? ''
        : JSON.parse(localStorage.getItem('auth-token')).typePerson,
    isVisible: true,
    loadInfo: false,
    showMeusVouchers: false,
  })

  history.listen(async (location, action) => {
    let usuario = verifyIsLoginActive()
    setIsLoggedIn(Boolean(usuario))
    if (Boolean(usuario)) {
      setState({ ...state, isPerson: usuario.typePerson })
    }
  })

  const [menuOpen, setMenuOpen] = useState(false)

  const handleOpenMenu = () => {
    setMenuOpen(!menuOpen)
  }

  function getInfo() {
    let usuario = verifyIsLoginActive()

    if (Boolean(usuario)) {
      setIsLoggedIn(usuario)
      setState({ ...state, isPerson: usuario.typePerson, loadInfo: true })
      let url = '/participants/v1/info'
      axios
        .get(url)
        .then((response) => {
          setState({ ...state, loadInfo: false })
          const infoUsuario = response
          const primeiroNome = response.name.split(' ')
          infoUsuario.firstName = primeiroNome[0]

          let usuario = verifyIsLoginActive()
          let showMeusVouchers = Boolean(usuario?.typePerson != 'Legal')

          setState({
            ...state,
            infoUsuario,
            showMeusVouchers,
          })
        })
        .catch((error) => {
          setState({ loadInfo: false })
          retryNumber = retryNumber + 1
          if (retryNumber === 4) {
            localStorage.removeItem('auth-token')
            setIsLoggedIn(false)
            history.push('/Login')
            setState({ ...state, loadInfo: false })
          } else {
            setTimeout(() => {
              retry()
              console.log('Erro - Retry ' + retryNumber, '[Erro]', error)
            }, 5000)
          }
        })
    } else {
      setIsLoggedIn(false)
    }
  }

  React.useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('auth-token')) || ''

    if (user?.typePerson === 'Legal') {
      setShowLegal(true)
    }
    getInfo()
  }, [])

  let retryNumber = 0

  const retry = () => {
    getInfo()
  }

  return (
    <>
      <Styled.Wrap>
        <UI.Container mobileFull>
          <Styled.Nav>
            <Styled.MobileMenuButton
              style={{ cursor: 'pointer' }}
              onClick={handleOpenMenu}
            >
              <MenuIcon />
            </Styled.MobileMenuButton>
            <Styled.Logo>
              <Styled.LogoWrap>
                <Logo />
              </Styled.LogoWrap>

              <Styled.NavItems>
                {isLoggedIn ? (
                  <NavItemsPrivate
                    isPerson={state.isPerson}
                    showMeusVouchers={state.showMeusVouchers}
                  />
                ) : (
                  <NavItems />
                )}
              </Styled.NavItems>
            </Styled.Logo>
            <Styled.Profile status={isLoggedIn}>
              {/* <Styled.Search>
                <NavbarSearch />
                <NavbarSearchMobile />
              </Styled.Search> */}
              {isLoggedIn ? (
                <NavbarProfilePrivate
                  infoUsuario={state.infoUsuario}
                  loadInfo={state.loadInfo}
                />
              ) : (
                <NavbarProfile />
              )}
            </Styled.Profile>
          </Styled.Nav>

          <Styled.MenuNav>
            <Styled.ButtonLink
              className={`${count == 1 ? 'active' : ''}`}
              onClick={() => handleChangeTab(1)}
            >
              <strong>Trocar Pontos</strong>
            </Styled.ButtonLink>
            <Styled.ButtonLink
              hidden={showLegal}
              className={`${count == 2 ? 'active' : ''}`}
              onClick={() => handleChangeTab(2)}
            >
              Comprar com <strong> desconto</strong>
            </Styled.ButtonLink>
            {/* <Styled.ButtonLink
              hidden={showLegal}
              className={`${count == 3 ? 'active' : ''}`}
              onClick={() => handleChangeTab(3)}
            >
              Comprar e <strong> ganhar pontos</strong>
            </Styled.ButtonLink> */}
          </Styled.MenuNav>
        </UI.Container>

        <MenuMobile
          open={menuOpen}
          setMenuOpen={handleOpenMenu}
          infoUsuario={state.infoUsuario}
          loadInfo={state.loadInfo}
          isPerson={state.isPerson}
        />
      </Styled.Wrap>
      <Styled.ProfileMobile>
        {isLoggedIn ? (
          <div
            style={{
              padding: '0px 20px',
              background: '#DBF1FF',
            }}
          >
            <NavbarProfilePrivate
              infoUsuario={state.infoUsuario}
              loadInfo={state.loadInfo}
              type={'menubar'}
            />
          </div>
        ) : (
          ''
        )}
      </Styled.ProfileMobile>
    </>
  )
}

const dispatch = (dispatch) =>
  bindActionCreators({ showModal: setModalShowRedux }, dispatch)

export default connect(null, dispatch)(Navbar)
